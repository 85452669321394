import { master } from "@/services/models";
import store from "@/store";

const options = {
  fetchProdi(filterData, currentInstitusi = false, lbl = "Pilih Prodi") {
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: lbl == "Pilih Prodi" ? "Pilih Prodi" : lbl,
      text: lbl == "Pilih Prodi" ? "Pilih Prodi" : lbl,
      selected: false,
    };
    listOptions.push(defaultOpt);
    master.Prodi.getAll().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.id_sms,
            text:
              item.jenjang + " " + item.nama + " - " + item?.induk?.nama_alias,
            id_sms: item.id_sms,
            kode_angka: item.kode_angka,
            kode_huruf: item.kode_huruf,
            nama: item.nama,
            jenjang: item.jenjang,
            induk: item.induk,
            selected: filterData?.prodi?.current?.id === item.id,
          };
          if (currentInstitusi === true) {
            let cins = store.getters["main/currentInstitusi"];
            if (cins?.id_sp === item?.id_sp) {
              listOptions.push(newoption);
            }
          } else {
            listOptions.push(newoption);
          }
        });
      }
      store.dispatch("main/listOptions", { prodi: listOptions });
    });
  },
  fetchFakultas(current) {
    let listOptions = [];
    let defaultOpt = {
      id: "0",
      value: "0",
      text: "Pilih Fakultas",
      selected: false,
    };
    listOptions.push(defaultOpt);
    master.MasterFakultas.getAllActive().then((result) => {
      if (result?.status === 200) {
        result?.data?.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.id,
            text: item.nama,
            kode_pddikti: item.kode_pddikti,
            nama: item.nama,
            nama_alias: item.nama_alias,
            id_sp: item.id_sp,
            selected: current?.id === item.id,
          };
          listOptions.push(newoption);
        });
      }

      store.dispatch("main/listOptions", { faculties: listOptions });
    });
  },

  fetchSemester(filterData) {
    //untuk penagihan
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Semester",
      selected: true,
    };
    listOptions.push(defaultOpt);
    master.Semester.getAllActive().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id_smt,
            value: item.id_smt,
            text: item.nm_smt,
            id_smt: item.id_smt,
            nm_smt: item.nm_smt,
            smt: item.smt,
            id_thn_ajaran: item.id_thn_ajaran,
            thn_ajaran: item.thn_ajaran,
            selected:
              String(filterData?.semester?.current?.id) === String(item.id_smt),
          };
          listOptions.push(newoption);
        });
      }
      store.dispatch("main/listOptions", { semester: listOptions });
    });
  },

  fetchSemesterAkad(filterData) {
    //untuk akademik
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Semester",
      selected: true,
    };
    listOptions.push(defaultOpt);
    master.SemesterAkad.getAllActive().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id_smt,
            value: item.id_smt,
            text: item.nm_smt,
            id_smt: item.id_smt,
            nm_smt: item.nm_smt,
            smt: item.smt,
            id_thn_ajaran: item.id_thn_ajaran,
            thn_ajaran: item.thn_ajaran,
            selected:
              String(filterData?.semester?.current?.id) === String(item.id_smt),
          };
          listOptions.push(newoption);
        });
      }
      store.dispatch("main/listOptions", { semester: listOptions });
    });
  },
};

const install = (app) => {
  app.config.globalProperties.$list = options;
};

export { install as default, options as option };
