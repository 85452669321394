const state = () => {
  return {
    feederserver: false,
    feedertoken: null,
    feederconnect: null,
  };
};

// getters
const getters = {
  feederconnect: (state) => state.feederconnect,
  feedererror: (state) => state.feedererror,
  feederserver: (state) => state.feederserver,
  feedertoken: (state) => state.feedertoken,
};

// actions
const actions = {
  feederconnect({ commit }, feederconnect) {
    commit("feederconnect", { feederconnect });
  },
  feederserver({ commit }, feederserver) {
    commit("feederserver", { feederserver });
  },
  feedertoken({ commit }, feedertoken) {
    commit("feedertoken", { feedertoken });
  },
  feedererror({ commit }, feedererror) {
    commit("feedererror", { feedererror });
  },
};

// mutations
const mutations = {
  feederconnect(state, { feederconnect }) {
    state.feederconnect = feederconnect;
  },
  feedertoken(state, { feedertoken }) {
    state.feedertoken = feedertoken;
  },
  feederserver(state, { feederserver }) {
    state.feederserver = feederserver;
  },
  feedererror(state, { feedererror }) {
    state.feedererror = feedererror;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
