import * as types from "./mutation-types";

const state = () => {
  return {
    darkMode: false,
    loadingStatus: false,
    //loading data
    isSuccess: null,
    responseMessage: "",
    responseCode: "",
    errorList: null,
    //university
    currentInstitusi: null,
    currentFakultas: null,
    currentSemester: null,
    currentTahunBiaya: null,
    currentKelompokBiaya: null,
    listOptions: [{ prodi: null, semester: null }],
    slideOverOpen: false,
    tabulatorDataLoaded: false,
  };
};

// getters
const getters = {
  darkMode: (state) => state.darkMode,
  loadingStatus: (state) => state.loadingStatus,
  isSuccess: (state) => state.isSuccess,
  responseMessage: (state) => state.responseMessage,
  responseCode: (state) => state.responseCode,
  errorList: (state) => state.errorList,
  currentInstitusi: (state) => state.currentInstitusi,
  currentFakultas: (state) => state.currentFakultas,
  currentSemester: (state) => state.currentSemester,
  currentTahunBiaya: (state) => state.currentTahunBiaya,
  currentKelompokBiaya: (state) => state.currentKelompokBiaya,
  listOptions: (state) => state.listOptions,
  listOptionsModel: (state, model) => {
    return state.listOptions[model];
  },
  slideOverOpen: (state) => state.slideOverOpen,
  tabulatorDataLoaded: (state) => state.tabulatorDataLoaded,
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  setLoadingStatus({ commit }, loadingStatus) {
    commit(types.SET_LOADING_MODE, { loadingStatus });
  },
  isSuccess({ commit }, isSuccess) {
    commit(types.SET_SUCCESS, { isSuccess });
  },
  responseMessage({ commit }, responseMessage) {
    commit(types.SET_MESSAGE, { responseMessage });
  },
  responseCode({ commit }, responseCode) {
    commit(types.SET_CODE, { responseCode });
  },
  errorList({ commit }, errorList) {
    commit("errorList", { errorList });
  },
  currentInstitusi({ commit }, currentInstitusi) {
    commit("currentInstitusi", { currentInstitusi });
  },
  currentFakultas({ commit }, currentFakultas) {
    commit("currentFakultas", { currentFakultas });
  },
  currentSemester({ commit }, currentSemester) {
    commit("currentSemester", { currentSemester });
  },
  currentTahunBiaya({ commit }, currentTahunBiaya) {
    commit("currentTahunBiaya", { currentTahunBiaya });
  },
  currentKelompokBiaya({ commit }, currentKelompokBiaya) {
    commit("currentKelompokBiaya", { currentKelompokBiaya });
  },
  listOptions({ commit }, listOptions) {
    commit("listOptions", { listOptions });
  },
  slideOverOpen({ commit }, slideOverOpen) {
    commit("slideOverOpen", { slideOverOpen });
  },
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  [types.SET_LOADING_MODE](state, { loadingStatus }) {
    state.loadingStatus = loadingStatus;
  },
  [types.SET_SUCCESS](state, { isSuccess }) {
    state.isSuccess = isSuccess;
  },
  [types.SET_MESSAGE](state, { responseMessage }) {
    state.responseMessage = responseMessage;
  },
  [types.SET_CODE](state, { responseCode }) {
    state.responseCode = responseCode;
  },
  errorList(state, { errorList }) {
    state.errorList = errorList;
  },
  currentInstitusi(state, { currentInstitusi }) {
    state.currentInstitusi = currentInstitusi;
  },
  currentFakultas(state, { currentFakultas }) {
    state.currentFakultas = currentFakultas;
  },
  currentSemester(state, { currentSemester }) {
    state.currentSemester = currentSemester;
  },
  currentTahunBiaya(state, { currentTahunBiaya }) {
    state.currentTahunBiaya = currentTahunBiaya;
  },
  currentKelompokBiaya(state, { currentKelompokBiaya }) {
    state.currentKelompokBiaya = currentKelompokBiaya;
  },
  listOptions(state, { listOptions }) {
    let key = Object.keys(listOptions)[0];
    state.listOptions[key] = listOptions[key];
  },
  slideOverOpen(state, { slideOverOpen }) {
    state.slideOverOpen = slideOverOpen;
  },
  clearMainState: (state) => {
    state.listOptions = [];
  },
  clearResponse: (state) => {
    state.responseMessage = "";
    state.responseCode = "";
    state.loadingStatus = null;
  },

  setTabulatorDataLoaded: (state) => {
    state.tabulatorDataLoaded = true;
  },
  resetTabulatorDataLoaded: (state) => {
    state.tabulatorDataLoaded = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
