const Layout = () =>
  import(/* webpackChunkName: "group-keuangan" */ "@/layouts/menu/Main");
const PermohonanAkademik = () =>
  import(
    /* webpackChunkName: "group-keuangan"*/ "../views/keuangan-mhs/permohonan-akademik/Main"
  );

const PermohonanKeuangan = () =>
  import(
    /* webpackChunkName: "group-keuangan"*/ "../views/keuangan-mhs/permohonan-keuangan/Main"
  );

const RekapTransaksiNota = () =>
  import(/* webpackChunkName: "group-keuangan"*/ "../views/nota-bernomor/Main");
const NotaBernomor = () =>
  import(/* webpackChunkName: "group-keuangan"*/ "../views/nota-bernomor/Form");

export const routes = {
  path: "/keuangan",
  name: "keuangan-route",
  component: Layout,
  children: [
    {
      name: "permohonan-akademik",
      path: "/permohonan-akademik",
      component: PermohonanAkademik,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "permohonan-keuangan",
      path: "/permohonan-keuangan",
      component: PermohonanKeuangan,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "nota-bernomor",
      path: "/nota-bernomor",
      component: NotaBernomor,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan", "nota"],
      },
    },
    {
      name: "rekap-nota-bernomor",
      path: "/rekap-nota-bernomor",
      component: RekapTransaksiNota,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan", "nota"],
      },
    },
  ],
};
