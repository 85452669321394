const Layout = () =>
  import(/* webpackChunkName: "group-master" */ "@/layouts/menu/Main");

const HonorNgajar = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/pmb/ValidasiBayar"
  );

const HonorSoal = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/pmb/RekapBiayaDaftar"
  );

export const routes = {
  path: "/pmb",
  name: "pmb-route",
  component: Layout,
  children: [
    {
      name: "validasi-biaya-daftar",
      path: "validasi-biaya-daftar",
      component: HonorNgajar,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "rekap-biaya-daftar",
      path: "rekap-biaya-daftar",
      component: HonorSoal,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
  ],
};
