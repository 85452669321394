import { REFHTTP } from "./http";
import store from "@/store";
export default class RefAPI {
  constructor(path) {
    this.path = path;
    this.endpoint = REFHTTP.getUri({ url: REFHTTP.defaults.baseURL + path });
  }
  getAll = () =>
    REFHTTP.get(`/${this.path}`).catch((error) => {
      this.apiError(error);
    });
  getData = (id) =>
    REFHTTP.get(`/${this.path}/${id}`).catch((error) => {
      this.apiError(error);
    });
  apiError = (error) => {
    if (error.response?.status === 401 || error.response?.status === 500)
      store.commit("auth/setError", error.response.data.message);

    // store.dispatch("main/isSuccess", false);
    // store.dispatch("main/responseMessage", error.response.data?.message);

    // if (error.response.data?.data) {
    //   store.dispatch("main/errorList", error.response.data?.data);
    // }
    return error;
  };
}
