import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import TailSelect from "./tail-select/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import Litepicker from "./litepicker/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";
import AutoComplete from "primevue/autocomplete";
import PrimeVue from "primevue/config";

const TinySlider = () =>
  import(
    /* webpackChunkName: "component-litepicker" */ "./tiny-slider/Main.vue"
  );
export default (app) => {
  app.use(PrimeVue);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("TailSelect", TailSelect);
  app.component("LoadingIcon", LoadingIcon);
  app.component("TinySlider", TinySlider);
  app.component("AutoComplete", AutoComplete);
  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
