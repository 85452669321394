const staffKeuanganMenus = [
  {
    icon: "HomeIcon",
    pageName: "dashboard",
    title: "Dashboard",
    ability: "*",
  },
];

const install = (app) => {
  app.config.globalProperties.$staff_menu = staffKeuanganMenus;
};

export { install as default, staffKeuanganMenus };
