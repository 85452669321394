const Pesertadidik = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/mahasiswa/pesertadidik/Main"
  );

const ListBiayaKuliah = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/master-keuangan/Main"
  );
const FormAddBiayaKuliah = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/master-keuangan/Form"
  );
const FormEditBiayaKuliah = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/master-keuangan/Edit"
  );
const FormPD = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/mahasiswa/pesertadidik/FormBiodata"
  );
const ViewPesertadidik = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/mahasiswa/pesertadidik/Detail"
  );

const FormAddBeasiswa = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/beasiswa/add"
  );

const FormEditBeasiswa = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/beasiswa/edit"
  );

const ListBeasiswa = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/beasiswa/main"
  );

const ListBank = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/bank/Main");

const FormBank = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/bank/Form");

const ListAkun = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/akun/Main");

const FormAddAkun = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/akun/Add");

const FormEditAkun = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/akun/Edit");

const ListJenisBiaya = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/jenisbiaya/Main"
  );

const FormAddJenisBiaya = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/jenisbiaya/Add"
  );

const FormEditJenisBiaya = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/jenisbiaya/Edit"
  );

const ListKelompokHonor = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/kelompok-honor/Main"
  );

const FormKelompokHonor = () =>
  import(
    /* webpackChunkName: "group-viewlist" */ "../views/master/kelompok-honor/Form"
  );

const ListHonorarium = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/honor/Main");

const FormHonorarium = () =>
  import(/* webpackChunkName: "group-viewlist" */ "../views/master/honor/Form");

export const viewList = [
  {
    parent: "mahasiswa-route",
    path: "pesertadidik",
    create: FormPD,
    edit: FormPD,
    list: Pesertadidik,
    view: ViewPesertadidik,
  },
  {
    parent: "mahasiswa-route",
    path: "master-keuangan",
    create: FormAddBiayaKuliah,
    edit: FormEditBiayaKuliah,
    list: ListBiayaKuliah,
  },
  {
    parent: "master-route",
    path: "bank",
    create: FormBank,
    edit: FormBank,
    list: ListBank,
  },
  {
    parent: "master-route",
    path: "akun",
    create: FormAddAkun,
    edit: FormEditAkun,
    list: ListAkun,
  },
  {
    parent: "master-route",
    path: "jenisbiaya",
    create: FormAddJenisBiaya,
    edit: FormEditJenisBiaya,
    list: ListJenisBiaya,
  },
  {
    parent: "master-route",
    path: "beasiswa",
    create: FormAddBeasiswa,
    edit: FormEditBeasiswa,
    list: ListBeasiswa,
  },
  {
    parent: "master-route",
    path: "kelompok-honor",
    create: FormKelompokHonor,
    edit: FormKelompokHonor,
    list: ListKelompokHonor,
  },
  {
    parent: "master-route",
    path: "formathonor",
    create: FormHonorarium,
    edit: FormHonorarium,
    list: ListHonorarium,
  },
];
