import { HTTP } from "@/services/http";

const state = () => {
  return {
    notifications: [],
    lastincoming: null,
    unreads: [],
  };
};
const mutations = {
  setNotifications: (state, data) => {
    state.notifications = data;
  },
  setUnreads: (state, data) => {
    state.unreads = data;
  },
};
const getters = {
  notifications: (state) => state.notifications,
  unreads: (state) => state.unreads,
};

const actions = {
  Notifications({ commit }) {
    HTTP.get("notifications").then((result) => {
      if (result.status === 200) {
        commit("setNotifications", result.data);
      }
    });
  },
  UnreadNotifications({ commit }) {
    HTTP.get("notifications/unreads").then((result) => {
      if (result.status === 200) {
        commit("setUnreads", result.data);
      }
    });
  },
  ReadAll({ commit }) {
    HTTP.get("notifications/readall").then((result) => {
      if (result.status === 200) {
        commit("setUnreads", []);
      }
    });
  },
  Clear({ commit }) {
    HTTP.get("notifications/clear").then((result) => {
      if (result.status === 200) {
        commit("setUnreads", []);
        commit("setNotifications", []);
      }
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
