import store from "@/store";
//import { useStore } from "vuex";
const getDefaultState = () => {
  return {
    byId: [],
    allIds: [],
  };
};
function makeCrudModule({ normalizeRelations = (x) => x, service } = {}) {
  return {
    // Actions for `create`, `update` and `delete` omitted for brevity.
    actions: {
      save: ({ commit }, { payload, initialValues }) => {
        if (!service) throw new Error("No service specified!");
        service
          .insertData(payload)
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              commit("add", normalizeRelations(response.data?.data));
              store.dispatch("main/responseMessage", response.data?.message);
              Object.keys(initialValues).forEach((val) => {
                payload[val] = "";
              });
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                store.dispatch("main/responseMessage", response.data?.message);
              }
            }
            return response;
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },
      all: async ({ commit }) => {
        // It is not strictly necessary to pass a service,
        // but if none was passed, no data can be loaded.
        if (!service) throw new Error("No service specified!");
        store.dispatch("main/setLoadingStatus", true);
        store.dispatch("main/isSuccess", null);
        await service
          .getAll()
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              /*response.data?.data.forEach(item => {
                // Noramlize nested data and swap the related objects
                // in the API response with an ID reference.
                commit("add", normalizeRelations(item));
              });*/
              if (response.data?.data) {
                commit("adds", response.data?.data);
              }
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                if (response.data?.status_code !== 200) {
                  store.dispatch(
                    "main/responseMessage",
                    response.data?.message
                  );
                  store.dispatch(
                    "main/responseCode",
                    response.data?.status_code
                  );
                }
              }
            }
            setTimeout(() => {
              store.dispatch("main/setLoadingStatus", false);
            }, 1000);
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },
      load: async ({ commit }, { id }) => {
        if (!service) throw new Error("No service specified!");
        store.dispatch("main/setLoadingStatus", true);
        service.getData(id).then((result) => {
          if (result.status === 200) {
            commit("add", result.data?.data);
            commit("addOne", result.data?.data);
          }
          setTimeout(() => {
            store.dispatch("main/setLoadingStatus", false);
          }, 1000);
        });
      },
      delete: async ({ commit }, id) => {
        if (!service) throw new Error("No service specified!");
        //commit("remove", { status: true, message: "test", id: id });
        //store.dispatch("main/isSuccess", true);
        await service
          .deleteData(id)
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              commit("remove", response.data);
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                if (response.data?.status_code !== 200) {
                  store.dispatch(
                    "main/responseMessage",
                    response.data?.message
                  );
                }
              } else {
                store.dispatch(
                  "main/responseMessage",
                  "Something wrong, please retry and check your network connection."
                );
              }
            }
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },
      update: ({ commit }, { id, payload }) => {
        if (!service) throw new Error("No service specified!");
        service
          .updateData(id, payload)
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              commit("update", normalizeRelations(response.data?.data));
              if (response.data?.data === undefined) {
                service.getData(id).then((result) => {
                  if (result.status === 200) {
                    commit("update", normalizeRelations(result.data?.data));
                  }
                });
              }

              store.dispatch("main/responseMessage", response.data?.message);
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                store.dispatch("main/responseMessage", response.data?.message);
              }
            }
            return response;
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },
      filter: async ({ commit }, filterData) => {
        if (!service) throw new Error("No service specified!");
        store.dispatch("main/setLoadingStatus", true);
        store.dispatch("main/isSuccess", null);
        let filter = [];
        for (const [key, value] of Object.entries(filterData)) {
          let vals = [];
          filterData[key].data.forEach((dt) => {
            vals.push(dt[value.field]);
          });
          filter[value.field] = vals;
        }
        await service
          .getAllFilterBy({ ...filter })
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              response.data?.data.forEach((item) => {
                commit("add", normalizeRelations(item));
              });
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                if (response.data?.status_code !== 200) {
                  store.dispatch(
                    "main/responseMessage",
                    response.data?.message
                  );
                  store.dispatch(
                    "main/responseCode",
                    response.data?.status_code
                  );
                }
              }
            }
            setTimeout(() => {
              store.dispatch("main/setLoadingStatus", false);
            }, 2500);
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },
      fetchAll: async ({ commit }, path) => {
        if (!service) throw new Error("No service specified!");
        store.dispatch("main/setLoadingStatus", true);
        store.dispatch("main/isSuccess", null);

        await service
          .fetchAll(path)
          .then((response) => {
            if (response !== undefined && response?.status === 200) {
              response.data?.data.forEach((item) => {
                commit("add", normalizeRelations(item));
              });
            } else {
              store.dispatch("main/isSuccess", false);
              if (response !== undefined) {
                if (response.data?.status_code !== 200) {
                  store.dispatch(
                    "main/responseMessage",
                    response.data?.message
                  );
                  store.dispatch(
                    "main/responseCode",
                    response.data?.status_code
                  );
                }
              }
            }
            setTimeout(() => {
              store.dispatch("main/setLoadingStatus", false);
            }, 2500);
          })
          .catch((error) => {
            store.dispatch("main/isSuccess", false);
            store.dispatch("main/responseMessage", error);
          });
      },

      loadid: async ({ commit }, { id }) => {
        if (!service) throw new Error("No service specified!");
        store.dispatch("main/setLoadingStatus", true);
        service.getData(id).then((result) => {
          if (result.status === 200) {
            commit("add", result.data?.data);
          }
          setTimeout(() => {
            store.dispatch("main/setLoadingStatus", false);
          }, 1000);
        });
      },
    },
    getters: {
      // Return a single item with the given id.
      find: (state) => (id) => {
        // Swap ID referenes with the resolved objects.
        if (state.byId.length > 1) {
          return state.byId.filter((item) => {
            return item.id.toString() === id.toString();
          })[0];
        } else {
          return state.byId[0];
        }
      },
      // Return a list of items in the order of `allIds`.
      list: (state) => {
        return state?.byId;
      },
      data: (state) => {
        return state?.data;
      },
    },
    mutations: {
      add: (state, item) => {
        store.dispatch("main/isSuccess", true);
        //not returning data after save
        if (item?.id === undefined) return;
        if (state.allIds.includes(item.id)) return;
        state.allIds.push(item.id);
        state.byId.push(item);
      },
      adds: (state, data) => {
        store.dispatch("main/isSuccess", true);
        state.data = [...data];
        state.byId = [...data];
      },
      addOne: (state, data) => {
        state.byId.push(data);
      },
      update: (state, item) => {
        store.dispatch("main/isSuccess", true);
        if (item !== undefined) {
          let index = state.byId.findIndex((i) => i.id === item.id);
          let dummy = state.byId.slice();
          dummy.splice(index, 1);
          state.byId = dummy;
          state.byId.push(item);
        }
      },
      remove: (state, item) => {
        store.dispatch("main/isSuccess", item.status);
        store.dispatch("main/responseMessage", item.message);
      },
      clear: (state) => {
        Object.assign(state, getDefaultState());
      },
    },
    namespaced: true,
    state: {
      byId: [],
      allIds: [],
      data: [],
    },
  };
}
export default makeCrudModule;
