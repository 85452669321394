import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import utils from "./utils";
import "./libs";

// SASS Theme
import "./assets/sass/app.scss";
//import wb from "./registerServiceWorker";

const app = createApp(App).use(store).use(router);

globalComponents(app);
utils(app);
app.config.globalProperties.app_title = process.env.VUE_APP_TITLE;
app.config.globalProperties.app_name = process.env.VUE_APP_NAME;
app.config.productionTip = false;
//app.config.globalProperties.$workbox = wb;
app.mount("#app");
