import { HTTPKEU } from "./http";
import store from "@/store";
import router from "@/router";

export default class APIKEU {
  constructor(path) {
    this.path = path;
    this.endpoint = HTTPKEU.getUri({ url: HTTPKEU.defaults.baseURL + path });
    this.basepath = HTTPKEU.getUri({ url: HTTPKEU.defaults.baseURL });
  }
  getAll = () =>
    HTTPKEU.get(`/${this.path}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  getData = (id) =>
    HTTPKEU.get(`/${this.path}/${id}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  insertData = (input) =>
    HTTPKEU.post(`/${this.path}`, input).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  updateData = (id, input) =>
    HTTPKEU.patch(`/${this.path}/${id}`, input).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  deleteData = (id) =>
    HTTPKEU.delete(`/${this.path}/${id}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  getAllActive = () =>
    HTTPKEU.get(`/is_active/${this.path}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  getAllActiveCustom = (query) =>
    HTTPKEU.get(`/is_active/${this.path}?${query}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  fetchUrlPrefix = (prefix, url = null) => {
    let fullurl =
      url?.length > 0
        ? `/${prefix}/${this.path}/${url}`
        : `/${prefix}/${this.path}`;
    return HTTPKEU.get(fullurl).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  };

  fetchUrl = (url) =>
    HTTPKEU.get(`/${this.path}/${url}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  fetchAll = (path) =>
    HTTPKEU.get(`/${this.path}/${path}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  getAllFilterBy = (param) =>
    HTTPKEU.post(`/filter/${this.path}`, param).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  searchData = (param) =>
    HTTPKEU.get(`/search/${this.path}`, param).catch((error) => {
      return Promise.reject(this.apiError(error));
    });

  syncData = (url, input) =>
    HTTPKEU.post(`/${this.path}/${url}`, input).catch((error) => {
      return Promise.reject(this.apiError(error));
    });

  getCustomPath = (path, params) =>
    HTTPKEU.get(`/${this.path}/${path}`, params).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  postCustomPath = (path, input) =>
    HTTPKEU.post(`/${this.path}/${path}`, input).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  postCustomPathBlob = (path, input) =>
    HTTPKEU.post(`/${this.path}/${path}`, input, {
      responseType: "arraybuffer",
    }).catch((error) => {
      return Promise.reject(this.apiError(error));
    });
  getQueryParam = (query) =>
    HTTPKEU.get(`/${this.path}?${query}`).catch((error) => {
      return Promise.reject(this.apiError(error));
    });

  get = (path = "/", params) =>
    HTTPKEU.get(
      path === "/" ? `/${this.path}` : `/${this.path}/${path}`,
      params
    ).catch((error) => {
      return Promise.reject(this.apiError(error));
    });

  apiError = (error) => {
    if (error.response?.status === 401) {
      store.commit("auth/setError", error.response.data.message);
      store.commit("userdata/clearUserData");
      store.commit("main/clearMainState");
      router.push({ name: "login" });
    } else if (error.response?.status === 500) {
      store.commit("auth/setError", error.response.data.message);
    }

    if (error.response?.status !== 401) {
      store.dispatch("main/isSuccess", false);
      if (error.response?.data?.message)
        store.dispatch("main/responseMessage", error.response?.data?.message);
      else
        store.dispatch(
          "main/responseMessage",
          "Maaf terjadi masalah, error code : " +
          error.response?.status +
          ". Ref url : " +
          error.response?.config?.url
        );

      if (error.response?.data?.data) {
        if (error.response?.data?.data.length > 1) {
          store.dispatch("main/errorList", error.response.data?.data);
        }
      }
    }

    return error.response;
  };
}
