import { mahasiswa } from "./mahasiswa";
import { perkuliahan } from "./perkuliahan";
import { keuangan } from "./keuangan";
import { master } from "./master";
import { ref } from "./ref";
import crud from "../../store/crud";
import store from "../../store";
//register you models here ...
//...
//and export her...

const moduleList = {
  master: master,
  ref: ref,
  mahasiswa: mahasiswa,
  perkuliahan: perkuliahan,
  keuangan: keuangan,
};

const dinamicService = (store, model) => {
  for (const [key, value] of Object.entries(model)) {
    store.registerModule(
      key,
      crud({
        service: value,
      })
    );
  }
};

for (const property in moduleList) {
  dinamicService(store, moduleList[property]);
}

export { dinamicService, master, ref, mahasiswa, keuangan, perkuliahan };
