import APIKEU from "../apikeu";

export const keuangan = {
  Akun: new APIKEU("master/akun"),
  Bank: new APIKEU("master/bank"),
  Beasiswa: new APIKEU("beasiswa"),
  Produk: new APIKEU("master/produk"),
  JenisBiaya: new APIKEU("master/jenisbiaya"),
  BiayaKuliah: new APIKEU("master/biaya-kuliah"),
  KelompokHonor: new APIKEU("master/kelompok-honor"),
  Honorarium: new APIKEU("master/honorarium"),
  PerhiutnganHonor: new APIKEU("perhitungan"),
  PendataanHonor: new APIKEU("pendataanhonor"),
  Pmb: new APIKEU("pmb"),
  MahasiswaCicilan: new APIKEU("mahasiswa_cicilan"),
  KasTransaksi: new APIKEU("kas_transaksi"), //Nota Bernomor
};
