<template>
  <div>
    <router-view v-if="sessionLock === false" :key="$route.fullPath" />
    <div
      id="lockup-modal"
      class="modal lock-modal flex h-screen"
      :class="sessionLock ? 'overflow-y-auto show' : 'hide'"
      tabindex="-1"
      aria-hidden="true"
      data-backdrop="static"
      :style="
        sessionLock
          ? 'margin-top: 0px; margin-left: 0px; padding-left: 0px; z-index: 10000;'
          : ''
      "
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-3">
              <div class="grid grid-cols-12 gap-2">
                <div class="col-span-4">
                  <img
                    alt="Icewall Tailwind HTML Admin Template"
                    :src="store.state.userdata.userData?.profile_photo_url"
                    class="w-full"
                  />
                </div>
                <div class="col-span-8">
                  <div
                    class="text-theme-19 dark:text-white font-light text-lg text-3xl"
                  >
                    {{ store.state.userdata.userData?.name }}
                  </div>
                  <div
                    class="text-theme-10 dark:text-gray-300 font-light text-sm"
                  >
                    {{
                      store.state.userdata.currentTeam?.name +
                      " - " +
                      store.state.userdata.userData?.uid
                    }}
                  </div>
                  <div
                    class="font-medium text-theme-1 dark:text-white text-md mt-3 mb-2"
                  >
                    Silahkan Masuk Kembali
                  </div>
                  <form method="post" @submit.prevent="unlock">
                    <div class="flex flex-row">
                      <div class="flex-1">
                        <div class="input-group">
                          <input
                            v-model="model.password"
                            :type="showPassword ? 'text' : 'password'"
                            required
                            class="intro-x form-control border-gray-300 block"
                            placeholder="Password"
                            autocomplete="off"
                            @submit="unlock"
                          />
                          <div class="input-group-text" @click="togglePassword">
                            <EyeOffIcon
                              v-if="showPassword"
                              class="text-theme-5"
                            />
                            <EyeIcon v-else class="text-theme-5" />
                          </div>
                        </div>
                      </div>
                      <div class="flex-initial ml-2">
                        <button type="submit" class="btn btn-success">
                          Masuk <UnlockIcon class="text-theme-5 ml-2" />
                        </button>
                      </div>
                    </div>
                  </form>

                  <button
                    class="text-theme-6 dark:text-pink-500 text-md mt-2 text-sm btn dark:border-white w-full"
                    @click="logOut"
                  >
                    Bukan {{ store.state.userdata.userData?.name }}? Klik Untuk
                    Logout!
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showError" class="modal-footer">
            <AlertFailed
              v-if="showError"
              :message="loginError"
              :dismissable="true"
              @closeAndClear="closeAndClear"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters, useStore } from "vuex";
import store from "@/store";
import AlertFailed from "@/components/alerts/AlertFailed";

export default defineComponent({
  components: { AlertFailed },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
  data() {
    return {
      model: {
        password: "",
      },
      showError: false,
      showPassword: false,
    };
  },
  computed: {
    loginError() {
      return this.$store.state.auth.error;
    },
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
    sessionLock() {
      return this.$store.state.auth.sessionLock;
    },
    unlockingSession() {
      return this.$store.state.auth.unlockingSession;
    },
  },
  watch: {
    loginError: {
      handler: function (h) {
        if (h.length > 0) this.showError = true;
      },
      immediate: true,
    },
  },
  mounted() {
    this.lock();
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });

      this.$workbox.addEventListener("activate", async () => {
        console.log("service worker activate");
      });

      //this.requestNotificationPermission();
    }
  },
  updated() {
    this.lock();
  },
  methods: {
    ...mapActions({
      LogOut: "auth/LogOut",
      RestoreRole: "auth/RestoreRole",
      Unlock: "auth/Unlock",
      SessionLock: "auth/SessionLock",
      ExtendSession: "auth/ExtendSessionLock",
    }),
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      loginDone: "auth/loginDone",
      isSessionLocked: "auth/isSessionLocked",
      isClientExpire: "auth/isClientExpire",
    }),
    unlock() {
      this.Unlock(this.model);
      this.model.password = "";
      //cash("#lockup-modal").modal("hide");
    },
    logOut() {
      cash("#dropdown-menu").dropdown("hide");
      //cash("#lockup-modal").modal("hide");
      this.LogOut();
    },
    lock() {
      const isAuthenticated = store.getters["auth/isAuthenticated"];
      const loginDone = store.getters["auth/loginDone"];
      const isSessionLocked = store.getters["auth/isSessionLocked"];

      if (isAuthenticated) {
        if (loginDone && isSessionLocked) {
          console.log("session locked", isSessionLocked);
          this.SessionLock();
        } else {
          //renew session lock
          this.ExtendSession();
        }
      }
      this.isClientExpire();
    },
    closeAndClear() {
      store.commit("auth/clearError");
      this.showError = false;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    async requestNotificationPermission() {
      const permission = await window.Notification.requestPermission();
      // value of permission can be 'granted', 'default', 'denied'
      // granted: user has accepted the request
      // default: user has dismissed the notification permission popup by clicking on x
      // denied: user has denied the request.
      if (permission !== "granted") {
        throw new Error("Permission not granted for Notification");
      }
    },
  },
});
</script>
<style>
body {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.content {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.tail-select {
  z-index: 100;
}

.block-loading {
  display: flex;
  position: absolute;
  background: #ffffffad;
  height: 100%;
  width: 100%;
  z-index: 99;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  flex-direction: column;
  padding-top: 5rem;
}
</style>
