import API from "../api";

export const master = {
  Fakultas: new API("masterfakultas"),
  MasterFakultas: new API("masterfakultas"),
  Prodi: new API("masterprodi"),
  Semester: new API("semester_keu"),
  SemesterAkad: new API("semester"),
  TahunAjaran: new API("tahun_ajaran"),
  Abilities: new API("abilities"),
  ListUserAbilities: new API("list_user_ability"),
  UserAbility: new API("user_ability"),
  User: new API("user"),
  Users: new API("users"),
  Team: new API("team"),
  Features: new API("features"),
  TeamAbility: new API("teamAbility"),
  Apps: new API("apps"),
  Konfigurasi: new API("konfig"),
  MasterInstitusi: new API("masterinstitusi"),
  Dosen: new API("dosen"),
  Jabfung: new API("jabfung"),
  /*PesertaDidik: new API("peserta_didik"),
  RegistasiPD: new API("registrasi_peserta_didik"),*/
};
