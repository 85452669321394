//import faker from "./faker";
import helper from "./helper";
import lodash from "./lodash";
import listoptions from "./listoptions";
import tabulators from "./tabulator";
import abilities from "./ability";
import user from "./userdata";

export default (app) => {
  //app.use(faker);
  app.use(helper);
  app.use(lodash);
  app.use(listoptions);
  app.use(tabulators);
  app.use(abilities);
  app.use(user);
};
