// import {
//   menuperkuliahan,
//   menuakademik,
//   menupesertadidik,
//   menudosen,
// } from "@/utils/menus";

const keuanganMenus = [
  {
    icon: "HomeIcon",
    pageName: "dashboard",
    title: "Dashboard",
    ability: "*",
  },
  {
    icon: "UserCheckIcon",
    pageName: "master",
    title: "Validasi",
    ability: ["tagihan"],
    subMenu: [
      {
        icon: "CheckIcon",
        ability: ["tagihan"],
        pageName: "tagihan-validasi",
        title: "Validasi Transaksi",
      },
      {
        icon: "PercentIcon",
        ability: ["tagihan"],
        pageName: "tagihan-tertagih-beasiswa",
        title: "Beasiswa Tertagih",
      },
      {
        icon: "PercentIcon",
        ability: ["tagihan"],
        pageName: "tagihan-tertagih",
        title: "Akan Lulus",
      },
    ],
  },
  {
    icon: "CommandIcon",
    pageName: "master",
    title: "Tagihan",
    ability: ["tagihan"],
    subMenu: [
      {
        icon: "PercentIcon",
        ability: ["tagihan"],
        pageName: "tagihan-pmb-list",
        title: "Tagihan UK Maba",
      },
      {
        icon: "PercentIcon",
        ability: ["tagihan"],
        pageName: "tagihan-uk-list",
        title: "Tagihan UK",
      },
      {
        icon: "PercentIcon",
        ability: ["tagihan"],
        pageName: "tagihan-sp-list",
        title: "Tagihan SP",
      },
    ],
  },
  {
    icon: "CommandIcon",
    pageName: "master",
    title: "Master",
    ability: ["master"],
    subMenu: [
      {
        icon: "DollarSignIcon",
        ability: ["master"],
        pageName: "bank",
        title: "Master Bank",
      },
      {
        icon: "BookOpenIcon",
        ability: ["master"],
        pageName: "master-keuangan",
        title: "Master Keuangan",
      },
      {
        icon: "TabletIcon",
        ability: ["master"],
        pageName: "akun",
        title: "Akun",
      },
      {
        icon: "PercentIcon",
        ability: ["master"],
        pageName: "beasiswa",
        title: "Beasiswa Mahasiswa",
      },
      {
        icon: "CodepenIcon",
        ability: ["master"],
        pageName: "jenis-biaya",
        title: "Jenis Biaya",
      },
      {
        icon: "AirplayIcon",
        ability: ["master"],
        pageName: "honorarium",
        title: "Honorarium",
        subMenu: [
          {
            icon: "ShoppingBagIcon",
            pageName: "kelompok-honor",
            title: "Kelompok Honor",
          },
          {
            icon: "FlagIcon",
            pageName: "formathonor",
            title: "Format Honor",
          },
        ],
      },
    ],
  },
  {
    icon: "CommandIcon",
    pageName: "perhitungan",
    title: "Perhitungan",
    ability: ["master"],
    subMenu: [
      {
        icon: "DollarSignIcon",
        ability: ["master"],
        pageName: "honor-ngajar",
        title: "Honor Mengajar",
      },
      {
        icon: "FileTextIcon",
        ability: ["master"],
        pageName: "honor-soal",
        title: "Honor Buat Soal",
      },
    ],
  },
  {
    icon: "StarIcon",
    pageName: "pmb",
    title: "PMB",
    ability: ["master"],
    subMenu: [
      {
        icon: "CheckIcon",
        ability: ["master"],
        pageName: "validasi-biaya-daftar",
        title: "Validasi Pendaftaran",
      },
      {
        icon: "FileTextIcon",
        ability: ["master"],
        pageName: "rekap-biaya-daftar",
        title: "Rekap Formulir PMB",
      },
    ],
  },
];

const install = (app) => {
  app.config.globalProperties.$ac_menu = keuanganMenus;
};

export { install as default, keuanganMenus };
