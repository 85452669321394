<template>
  <div v-if="message?.length > 0">
    <div
      :class="alertclass"
      class="alert alert-danger show flex items-center mb-2"
      role="alert"
    >
      <AlertOctagonIcon class="w-6 h-6 mr-2" />
      {{ message }}
      <button
        v-if="dismissable"
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        @click="closeAndClear"
      >
        <XIcon class="w-4 h-4" />
      </button>
    </div>
    <div v-if="errorList">
      <div
        class="alert alert-danger-soft show flex items-center mb-2"
        role="alert"
      >
        <ul v-for="(list, k) in errorList" :key="k">
          <li v-for="(item, i) in list" :key="i">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertFailed",
  props: {
    message: {
      type: String,
      default: "Failed ...",
    },
    alertstyle: {
      type: String,
      default: "alert-danger", //alert-outline-danger | alert-danger-soft
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorList() {
      return this.$store.state.main.errorList;
    },
    alertclass() {
      return this.dismissable === true
        ? this.alertstyle + " alert-dismissible"
        : this.alertstyle;
    },
  },
  methods: {
    closeAndClear() {
      this.$emit("closeAndClear", null);
      console.log("dismiss");
    },
  },
};
</script>
