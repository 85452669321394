import { HTTP } from "@/services";
import store from "@/store/index";

const state = () => {
  return {
    userData: null,
    originalUserData: null,
    currentTeam: null,
    tempTeam: null,
    teams: null,
    dosenProdi: null,
    dosenKaprodi: null,
    dosenDekan: null,
    dosenRektor: null,
    localLevel: null,
  };
};

const mutations = {
  setUserData: (state, model) => {
    state.userData = model.userdata;
    state.currentTeam = model.current_team;
    state.teams = model.teams;
    state.tempTeam = model.teams;
  },
  clearUserData: (state) => {
    state.userData = null;
    state.originalUserData = null;
    state.currentTeam = null;
    state.tempTeam = null;
    state.teams = null;
    state.dosenProdi = null;
    state.dosenKaprodi = null;
    state.dosenDekan = null;
    state.dosenRektor = null;
  },
  setoriginalUserData: (state, model) => {
    state.originalUserData = model.userdata;
  },
  setCurrentTeam: (state, team) => {
    state.currentTeam = team;
  },
  setTempTeam: (state, team) => {
    state.tempTeam = team;
  },
  dosenProdi: (state, data) => {
    state.dosenProdi = data;
  },
  dosenKaprodi: (state, data) => {
    state.dosenKaprodi = data;
  },
  dosenDekan: (state, data) => {
    state.dosenDekan = data;
  },
  dosenRektor: (state, data) => {
    state.dosenRektor = data;
  },
  setLocalLevel: (state, data) => {
    state.localLevel = data;
  },
};

const getters = {
  userData: (state) => state.userData,
  originalUserData: (state) => state.originalUserData,
  currentTeam: (state) => state.currentTeam,
  currentTeamId: (state) => {
    return state.currentTeam?.id.toString();
  },
  currentTeamName: (state) => {
    return state.currentTeam?.name.toString();
  },
  teams: (state) => state.teams,
  tempTeam: (state) => state.tempTeam,
  dosenProdi: (state) => state.dosenProdi,
  dosenDekan: (state) => state.dosenDekan,
  dosenKaprodi: (state) => state.dosenKaprodi,
  dosenRektor: (state) => state.dosenRektor,
  localLevel: (state) => state.localLevel,
};
const actions = {
  dosenProdi({ commit }) {
    HTTP.get("/userdosen")
      .then((result) => {
        let dosen = result?.data;
        commit("dosenProdi", dosen);

        store.dispatch("main/currentFakultas", dosen?.fakultas);
        store.dispatch("main/currentInstitusi", dosen?.satuan_pendidikan);
      })
      .catch((err) => {
        if (err.response?.status === 404)
          store.dispatch(
            "main/responseMessage",
            "Dosen tidak terdaftar pada PDDIKTI / tidak memiliki NIDN"
          );
      });
  },
  dosenKaprodi({ commit }) {
    HTTP.get("/userkaprodi").then((result) => {
      let kaprodi = result?.data;
      commit("dosenKaprodi", kaprodi);
      store.dispatch("main/currentFakultas", kaprodi?.fakultas);
      store.dispatch("main/currentInstitusi", kaprodi?.satuan_pendidikan);
    });
  },
  dosenDekan({ commit }) {
    HTTP.get("/userdekan").then((result) => {
      let fakultas = result?.data;
      commit("dosenDekan", fakultas);
      store.dispatch("main/currentFakultas", fakultas?.fakultas);
      store.dispatch("main/currentInstitusi", fakultas?.satuan_pendidikan);
    });
  },
  dosenRektor({ commit }) {
    HTTP.get("/userrekor").then((result) => {
      let rektor = result?.data;
      commit("dosenRektor", rektor);
      store.dispatch("main/currentInstitusi", rektor?.satuan_pendidikan);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
