import API from "../api";

export const perkuliahan = {
  AktMhs: new API("akt_mhs"),
  kuliahMhs: new API("kuliahmhs"),
  KlsKuliah: new API("kelaskuliah"),
  Jadwal: new API("jadwal"),
  JadwalKelasKuliah: new API("jadwalKelasKuliah"),
  NilaiSemesterMahasiswa: new API("nilaismt_mhs"),
  Hari: new API("hari"),
  Jam: new API("jam"),
  WaktuJamKuliah: new API("waktuJadwalKuliah"),
  JenisPertemuan: new API("jenisPertemuan"),
  Pertemuan: new API("pertemuan"),
  PertemuanAbsensi: new API("pertemuanAbsensi"),
  PengaturanPenilaian: new API("pengaturanPenilaian"),
  PenilaianMhs: new API("penilaianMhs"),
  NilaiTranskrip: new API("nilai_transkrip"),
  AnggotaAktMhs: new API("anggota_aktMhs"),
  KategoriKegiatan: new API("kategoriKegiatan"),
  BimbingMhs: new API("bimbing_mhs"),
  UjiMhs: new API("uji_mhs"),
  KonversiAktMhs: new API("konversiAktMhs"),
  EkuivTransfer: new API("ekuivTransfer"),
  JadwalUtsUas: new API("jadwalutsuas"),
  Rekap: new API("rekap"),
  daftarKerjaPraktek: new API("daftarKerjaPraktek"),
  daftarSkripsi: new API("daftarSkripsi"),
  AktAjarDosen: new API("akt_ajar_dosen"),
  TugasTambahan: new API("tugas_tambahan"),
  CetakTranskrip: new API("cetakTranskrip"),
  CetakKartuKendali: new API("cetakKartuKendali"),
  Litabmas: new API("litabmas"),
  PertemuanTambahan: new API("pertemuan_tambahan"),
};
