import { keuanganMenus } from "@/utils/keuangan-menus";
import { staffKeuanganMenus } from "@/utils/staff-keuangan-menus";

const state = () => {
  return {
    menu: keuanganMenus,
  };
};

// getters
const getters = {
  menu: (state, getters) => {
    if (getters.isKepalaUnit) {
      return state.menu;
    } else if (getters.isAdminStaff) {
      return staffKeuanganMenus;
    } else if (getters.isSuperAdmin) {
      return keuanganMenus;
    } else {
      return [
        {
          icon: "HomeIcon",
          pageName: "dashboard",
          title: "Dashboard",
        },
      ];
    }
    /*let canall =
      getters.usrAbility?.length > 0
        ? getters.usrAbility.find((a) => a === "*")
        : undefined;
    if (canall === undefined && getters.usrAbility?.length > 0) {
      if (getters.adminacad.includes(getters.cteam)) {
        let menulist = state.menu.filter((a) => {
          return a?.ability == "*";
        });

        let abl = getters.usrAbility.map((a) => a.split(":", 1)[0]);
        let finalabl = abl.filter(function (item, pos) {
          return abl.indexOf(item) == pos;
        });
        finalabl.forEach((b) => {
          let m = state.menu.find((a) => {
            return a?.ability?.includes(b);
          });
          if (m !== undefined) menulist.push(m);
        });

        return menulist.filter(function (item, pos) {
          return menulist.indexOf(item) == pos;
        });
      } else {
        return [
          {
            icon: "HomeIcon",
            pageName: "dashboard",
            title: "Dashboard",
          },
        ];
      }
    } else {
      return state.menu;
    }*/
  },
  usrAbility: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/abilities"];
  },
  cteam: (state, getters, rootState, rootGetters) => {
    return rootGetters["userdata/currentTeamId"];
  },
  adminacad: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/acadteam"];
  },
  isDosen: (state, getters, rootState, rootGetters) => {
    let cid = rootGetters["userdata/currentTeamId"];
    let ctn = rootGetters["userdata/currentTeamName"];
    return cid == 3 || ctn == "Dosen";
  },
  isKaprodi: (state, getters, rootState, rootGetters) => {
    let cid = rootGetters["userdata/currentTeamId"];
    let ctn = rootGetters["userdata/currentTeamName"];
    return cid == 8 || ctn == "Kaprodi";
  },
  isDekan: (state, getters, rootState, rootGetters) => {
    let cid = rootGetters["userdata/currentTeamId"];
    let ctn = rootGetters["userdata/currentTeamName"];
    return cid == 7 || ctn == "Dekan";
  },
  isRektor: (state, getters, rootState, rootGetters) => {
    let cid = rootGetters["userdata/currentTeamId"];
    let ctn = rootGetters["userdata/currentTeamName"];
    return cid == 6 || ctn == "Rektor";
  },
  isSuperAdmin: (state, getters, rootState, rootGetters) => {
    let cid = rootGetters["userdata/currentTeamId"];
    return cid == 1 && rootState["userdata"].userData?.uid == "113064";
  },
  isAdminStaff: (state, getters, rootState, rootGetters) => {
    let localLevel = rootGetters["userdata/localLevel"];
    return localLevel.id === 4;
  },
  isKepalaUnit: (state, getters, rootState, rootGetters) => {
    let localLevel = rootGetters["userdata/localLevel"];
    return localLevel.id === 3;
  },
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
