import {
  csrfCookie,
  refreshToken,
  setTeam,
  signIn,
  signOut,
  switchRole,
  unlock,
} from "@/services/auth";
import router from "@/router";
import store from "@/store";

const acadteamgroup = ["1", "4", "9"];
const dosenteamgroup = ["3", "6", "7", "8"];
const keuteamsagroup = ["9"];
const keuteamstaffgroup = ["9"];
const state = () => {
  return {
    token: "",
    expiration: "",
    isBusy: false,
    error: "",
    abilities: "",
    features: "",
    autoExtend: false,
    pickTeam: false,
    loginDone: false,
    loginas: false,
    acadteam: acadteamgroup,
    dosenteam: dosenteamgroup,
    sessionLockTime: new Date(),
    sessionLock: false,
    unlockingSession: false,
  };
};

const mutations = {
  setBusy: (state) => (state.isBusy = true),
  clearBusy: (state) => (state.isBusy = false),
  setError: (state, error) => (state.error = error),
  clearError: (state) => (state.error = ""),
  setToken: (state, model) => {
    state.token = model.token_name;
    state.expiration = new Date(model.expiration).toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    });
    state.sessionLockTime = new Date(
      new Date().getTime() + process.env.VUE_APP_EXP_DUR * 60000
    );
  },
  clearToken: (state) => {
    state.token = "";
    state.expiration = new Date();
    state.abilities = null;
    state.loginas = false;
    state.sessionLockTime = new Date();
    state.sessionLock = false;
    localStorage.clear();
  },
  setAbilities: (state, model) => {
    state.abilities = model.ability;
  },
  setFeatures: (state, model) => {
    state.features = model.feature;
  },
  setAccessToken: (state, access_token) => {
    localStorage.setItem("_bh", JSON.stringify(access_token));
  },
  setTempAccessToken: (state, access_token) => {
    localStorage.setItem("_bh_tmp", JSON.stringify(access_token));
  },
  clearTempToken: () => {
    delete localStorage._bh_tmp;
  },
  setAutoExtend: (state) => {
    state.autoExtend = !state.autoExtend;
  },
  pickTeam: (state, status = true) => {
    state.pickTeam = status;
  },
  loginDone: (state, status = true) => {
    state.loginDone = status;
    document.cookie =
      "_on_duty=" +
      new Date() +
      "; expires=" +
      state.sessionLockTime +
      "; path=/;";
    sessionStorage.setItem("_on_duty", new Date());
  },
  loginAs: (state, status = true) => {
    state.loginas = status;
  },
  unlock: (state) => {
    state.sessionLockTime = new Date(
      new Date().getTime() + process.env.VUE_APP_EXP_DUR * 60000
    );
    state.sessionLock = false;
    sessionStorage.setItem("_on_duty", new Date());
    document.cookie =
      "_on_duty=" +
      new Date() +
      "; expires=" +
      state.sessionLockTime +
      "; path=/;";
  },
  sessionLock: (state) => {
    state.sessionLock = true; //new Date(state.sessionLockTime) < new Date();
  },
  unlockingSession: (state, status = true) => {
    state.unlockingSession = status;
  },
};

const getters = {
  isAuthenticated: (state) => state.token.length > 0,
  isExpire: (state) =>
    state.expiration <
    new Date().toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    }),
  abilities: (state) => state.abilities,
  features: (state) => state.features,
  accessToken: (state) => {
    let ls = state.loginas ? "_bh_tmp" : "_bh";
    return JSON.parse(localStorage.getItem(ls));
  },
  tempAccessToken: () => JSON.parse(localStorage.getItem("_bh_temp")),
  isAutoExtend: (state) => state.autoExtend,
  error: (state) => state.error,
  acadteam: (state) => state.acadteam,
  dosenteam: (state) => state.dosenteam,
  isSuperAdmin: (state, getters) => {
    let su =
      getters.abilities?.length > 0
        ? getters.abilities?.find((a) => a === "*")
        : undefined;
    return su !== undefined;
  },
  pickTeam: (state) => state.pickTeam,
  loginDone: (state) => state.loginDone,
  tokenName: (state) => state.token,
  loginAs: (state) => state.loginas,
  isSessionLocked: (state) => {
    //let sesslt = sessionStorage.getItem("_on_duty");
    //if (sesslt) {
    return new Date(state.sessionLockTime) < new Date();
    /*} else {
      return true;
    }*/
  },
  isClientExpire: (state) => {
    return new Date(state.expiration) < new Date();
  },
  unlockingSession: (state) => state.unlockingSession,
};

const actions = {
  LogIn({ commit, dispatch }, user) {
    commit("setBusy");
    commit("clearError");
    commit("pickTeam", false);
    commit("loginDone", false);
    store.dispatch("main/setLoadingStatus", true);
    signIn(user)
      .then((result) => {
        if (result.status === 200) {
          commit("setToken", result.data);
          commit("setAbilities", result.data);
          commit("setFeatures", result.data);
          commit("setAccessToken", result.data.access_token);
          store.commit("userdata/setUserData", result.data);
          let teams = result?.data?.teams;
          console.log(result.data, store.getters["userdata/teams"]);
          if (Array.isArray(teams) && teams.length === 1) {
            commit("loginDone");
            dispatch("TeamMetaData");
            router.go(1);
            router.push("/");
          } else {
            //let allowstaff = teams.find((t) => t.name === "Staff");
            let allowstaff = teams.find((t) => t.name === "Staff");
            let allowkepala = teams.find((t) => t.name === "Kepala Unit");
            let allowrektor = teams.find((t) => t.name === "Rektor");

            if (allowstaff || allowkepala || allowrektor) {
              commit("loginDone");
              dispatch("TeamMetaData");
              router.go(1);
              router.push("/");
            } else {
              commit("setError", "Sorry, you don't have access to this system");
            }
          }
        } else {
          commit("setError", result.data?.message);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403)
          commit("setError", error.response.data.message);
      })
      .finally(() => {
        store.dispatch("main/setLoadingStatus", false);
      });
  },
  LogOut: ({ commit }) => {
    commit("clearToken");
    commit("pickTeam", false);
    commit("loginDone", false);
    store.commit("userdata/clearUserData");
    store.commit("main/clearMainState");
    router.push({ name: "login" });
  },
  async RefreshToken({ commit, state }) {
    let data = new FormData();
    data.append("apps", state.token);
    await refreshToken(data)
      .then((result) => {
        if (result.status === 200) {
          commit("setToken", result.data);
          commit("setAccessToken", result.data.access_token);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403)
          commit("setError", error.response.data.message);
        else commit("setError", "Authentication Failed");
      });
  },
  SetTeam({ commit, dispatch }, payload) {
    let data = new FormData();
    data.append("teamid", payload.id);
    store.dispatch("main/setLoadingStatus", true);
    setTeam(data)
      .then((result) => {
        if (result.status === 200) {
          if (result.data.status == true) {
            store.commit("userdata/setCurrentTeam", result?.data?.current_team);
            commit("setAbilities", result?.data);
            commit("setFeatures", result?.data);
          }
          dispatch("TeamMetaData");
          commit("loginDone", true);
          commit("pickTeam", false);
          if (payload.refresh) {
            router.go(1);
            router.push("/");
          }
        } else {
          commit(
            "setError",
            "Login gagal, tidak dapat mengganti Role/Team/Level"
          );
        }
      })
      .finally(() => {
        store.dispatch("main/setLoadingStatus", false);
      });
  },
  SwitchRole({ commit }, user) {
    commit("setBusy");
    commit("clearError");
    commit("pickTeam", false);
    store.dispatch("main/setLoadingStatus", true);
    switchRole(user)
      .then((result) => {
        if (result.data.status_code === 200) {
          commit("setAbilities", result.data);
          commit("setFeatures", result.data);
          commit("setTempAccessToken", result.data.temp_access_token);
          store.commit("userdata/setUserData", result.data);
          store.commit("userdata/setoriginalUserData", result.data.original);
          let teams = result?.data?.teams;
          if (Array.isArray(teams) && teams.length === 1) {
            router.go(1);
            router.push("/");
          } else {
            commit("pickTeam", true);
          }
          commit("loginAs", true);
        } else {
          commit("setError", result.data?.message);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401 || error.response?.status === 403)
          commit("setError", error.response.data.message);
        else commit("setError", "Authentication Failed");
      })
      .finally(() => {
        store.dispatch("main/setLoadingStatus", false);
      });
  },
  RestoreRole({ commit, state }) {
    commit("loginAs", false);
    commit("clearTempToken");
    let data = new FormData();
    data.append("apps", state.token);
    refreshToken(data).then((result) => {
      if (result.status === 200) {
        commit("setToken", result.data);
        commit("setAccessToken", result.data.access_token);
        commit("setAbilities", result.data);
        commit("setFeatures", result.data);
        store.commit("userdata/setUserData", result.data);
      } else {
        commit("setError", result?.data?.message);
      }
      router.go(1);
      router.push("/");
    });
  },
  TeamMetaData() {
    //let currentTeamId = store.getters["userdata/currentTeamId"];
    let teams = store.getters["userdata/teams"];

    /* if (currentTeamId === "3") store.dispatch("userdata/dosenProdi");
    if (currentTeamId === "8") store.dispatch("userdata/dosenKaprodi");
    if (currentTeamId === "7") store.dispatch("userdata/dosenDekan");*/
    let isrektor = teams.find((t) => t.name === "Rektor");
    if (isrektor) {
      store.dispatch("userdata/dosenRektor");
      let rektor = store.getters["userdata/dosenRektor"];
      if (rektor.rektor.length > 0)
        store.commit("userdata/setLocalLevel", {
          level: "rektor",
          id: 1,
          name: "Rektor",
        });
      else if (rektor.wakil_rektor2.length > 0)
        store.commit("userdata/setLocalLevel", {
          level: "wr2",
          id: 2,
          name: "Wakil Rektor 2",
        });
      else
        store.commit("userdata/setLocalLevel", {
          level: "user",
          id: 0,
          name: "User",
        });
    } else {
      let isStaff = teams.find((t) => t.name === "Staff");
      let isKepalaUnit = teams.find((t) => t.name === "Kepala Unit");
      if (isKepalaUnit) {
        store.commit("userdata/setLocalLevel", {
          level: "bak",
          id: 3,
          name: "Kepala BAK",
        });
      } else if (isStaff) {
        store.commit("userdata/setLocalLevel", {
          level: "adm",
          id: 4,
          name: "Staff Adminisitrasi",
        });
      } else {
        store.commit("userdata/setLocalLevel", {
          level: "user",
          id: 0,
          name: "User",
        });
      }
    }
    console.log(store.getters["userdata/localLevel"]);
  },
  Unlock: ({ commit, dispatch }, user) => {
    commit("unlockingSession", false);
    unlock(user)
      .then((res) => {
        if (res.status === 200) {
          commit("unlockingSession", true);
          commit("unlock");
          dispatch("RefreshToken");
        }
      })
      .catch((error) => {
        if (error.response?.status === 403)
          commit("setError", error.response.data.message);
        else if (error.response?.status === 401) {
          commit(
            "setError",
            "You have been inactive more than 1 hour, please Logout and Login again!"
          );
        } else
          commit(
            "setError",
            "Authentication failed, please Logout and Login again!"
          );

        commit("unlockingSession", false);
      })
      .finally(() => {});
  },
  SessionLock: ({ commit }) => {
    commit("sessionLock");
  },
  ExtendSessionLock: ({ commit }) => {
    commit("unlock");
  },
  RevokeToken: () => {
    signOut();
  },
  CSRF({ commit }) {
    csrfCookie().catch((error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        commit(
          "setError",
          error.response.data.message + " (" + error.response?.status + ")"
        );
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
