import store from "@/store";

const user = {
  isKepalaUnit() {
    let localLevel = store.getters["userdata/localLevel"];
    return localLevel.id === 3;
  },
  isWR2() {
    let localLevel = store.getters["userdata/localLevel"];
    return localLevel.id === 2;
  },
  isRektor: () => {
    let localLevel = store.getters["userdata/localLevel"];
    return localLevel.id === 1;
  },
  isAdminStaff() {
    let localLevel = store.getters["userdata/localLevel"];
    return localLevel.id === 4;
  },
  isSuperAdmin() {
    let cid = store.getters["userdata/currentTeamId"];
    return cid == 1 && store.state.userdata.userData?.uid == "113064";
  },

  currentTeam() {
    return store.getters["userdata/currentTeam"];
  },
  currentTeamId() {
    return store.getters["userdata/currentTeamId"];
  },
  currentTeamName() {
    return store.getters["userdata/currentTeamName"];
  },

  team() {
    return store.getters["userdata/teams"];
  },

  dosenprodi() {
    return store.getters["userdata/dosenProdi"];
  },
  kaprodi() {
    return store.getters["userdata/dosenKaprodi"];
  },
  dekan() {
    return store.getters["userdata/dosenDekan"];
  },
  rektor() {
    return store.getters["userdata/dosenRektor"];
  },
  isAuthenticated() {
    return store.getters["auth/isAuthenticated"];
  },
  isAcademicTeam() {
    let cid = store.getters["userdata/currentTeamId"];
    let adminacad = store.state.auth.acadteam;
    return adminacad.includes(cid?.toString());
  },
  userdata: store.getters["userdata/userData"],
  level: store.getters["userdata/localLevel"],
};

const install = (app) => {
  app.config.globalProperties.$u = user;
  app.config.globalProperties.isSuperAdmin = user.isSuperAdmin;
  app.config.globalProperties.isAcademicTeam = user.isAcademicTeam;
  app.config.globalProperties.userdata = user.userdata;
};

export { install as default, user as userdata};
