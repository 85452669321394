import { routes as master } from "@/router/master";
import store from "@/store";

const ability = [];

const menuability = [
  {
    name: "kelas",
    ability: ["kelas", "kelas:view", "kelas:list"],
    route: ["kls-kuliah", "jadwal-kuliah"],
  },
  {
    name: "absensi",
    ability: ["absensi", "absensi:view", "absensi:list"],
    route: ["konfirmasi-mengajar", "presensi"],
  },
];

const cekRoute = (canaccess, to) => {
  let ftarget = undefined;
  canaccess.forEach((a) => {
    if (a?.to?.name === to.name) return a?.to;
    if (ftarget === undefined) {
      ftarget = a?.to?.children?.find((b) => {
        return b?.name === to.name;
      });
    }
  });
  return ftarget;
};

const cekDynRoute = (canaccess, to) => {
  let ftarget = undefined;
  canaccess.forEach((a) => {
    if (to.name.includes(a?.to?.name)) return true;

    if (ftarget === undefined) {
      ftarget = a?.to?.children.find((b) => {
        return to.name.includes(b?.name);
      });
    }
  });
  return ftarget !== undefined;
};

const cekSpecRoute = (t_abs, to) => {
  let specroute = false;
  let cr = undefined;

  t_abs.forEach((a) => {
    let acc = ability.find((b) => {
      return a.includes(b.ability);
    });

    if (a.includes(":list") || a.includes(acc?.ability)) {
      if (specroute === false && to.name?.includes(acc?.ability))
        specroute = true;

      if (specroute === false && cr === undefined && acc?.to?.children) {
        cr = acc?.to?.children.find((b) => {
          return to.name.includes(b?.name);
        });
        if (cr !== undefined) specroute = true;
      }

      if (specroute === false && cr === undefined && Array.isArray(acc?.to)) {
        cr = acc?.to?.find((b) => {
          return (
            to.name === b.name && b?.ability && b?.ability.includes(acc.ability)
          );
        });
        if (cr !== undefined) specroute = true;
      }
    }
  });

  if (specroute) return true;
};

const abilities = {
  cekAbility: (t_abs, to) => {
    let canaccess = [];
    //general abilities
    t_abs.forEach((a) => {
      let acc = ability.find((b) => {
        return a.includes(b.ability);
      });
      if (acc !== undefined && a === acc.ability) canaccess.push(acc);
    });

    //access to route / module
    let ftarget = cekRoute(canaccess, to);
    if (ftarget !== undefined) {
      return ftarget?.name === to.name;
    }

    if (to.name?.includes("view")) {
      if (canaccess.length > 0) ftarget = cekDynRoute(canaccess, to, "view");
      else ftarget = cekSpecRoute(t_abs, to);
      return ftarget;
    } else if (to.name?.includes("create")) {
      if (canaccess.length > 0) ftarget = cekDynRoute(canaccess, to, "create");
      else ftarget = cekSpecRoute(t_abs, to);
      return ftarget;
    } else if (to.name?.includes("edit")) {
      if (canaccess.length > 0) ftarget = cekDynRoute(canaccess, to, "edit");
      else ftarget = cekSpecRoute(t_abs, to);
      return ftarget;
    } else if (to.name?.includes("list")) {
      if (canaccess.length > 0) ftarget = cekDynRoute(canaccess, to, "list");
      else ftarget = cekSpecRoute(t_abs, to);
      return ftarget;
    } else {
      //specific ability
      return cekSpecRoute(t_abs, to);
    }

    //return false;
  },
  isSuperAdmin: (t_abs, to) => {
    let canall = t_abs.find((a) => a === "*");
    if (canall === undefined) {
      if (["profile", "datapribadi"].includes(to.name)) return true;
      if (to.name !== "dashboard") return false;
    }
    return true;
  },
};

const hasAbility = (f) => {
  const usr_ability = store.getters["auth/abilities"];
  return usr_ability?.includes(f);
};

const hasFeatures = (f) => {
  const usr_ability = store.getters["auth/abilities"];
  if (usr_ability?.includes("*")) return true;
  let features = store.getters["auth/features"];
  if (!Array.isArray(features)) return false;
  if (features.includes("*")) return true;
  return features?.includes(f);
};

const hasMainFeatures = (f) => {
  const usr_ability = store.getters["auth/abilities"];
  if (usr_ability?.includes("*")) return true;
  let features = store.getters["auth/features"];
  if (!Array.isArray(features)) return false;
  if (features.includes("*")) return true;
  let abl = features?.map((a) => a.split(":", 1)[0]);
  let finalabl = abl?.filter(function (item, pos) {
    return abl?.indexOf(item) == pos;
  });
  return finalabl?.includes(f);
};

const install = (app) => {
  app.config.globalProperties.$aby = ability;
  app.config.globalProperties.hasAbility = hasAbility;
  app.config.globalProperties.hasFeatures = hasFeatures;
  app.config.globalProperties.hasMainFeatures = hasMainFeatures;
};

export {
  install as default,
  abilities,
  menuability,
  ability,
  hasAbility,
  hasFeatures,
};
