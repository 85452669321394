const Layout = () =>
  import(/* webpackChunkName: "group-tagihan" */ "@/layouts/menu/Main");
const BuatTagihanSP = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/semester-pendek/Form"
  );
const BuatTagihanUK = () =>
  import(/* webpackChunkName: "group-tagihan"*/ "../views/tagihan/UK/Form");
const BuatTagihanUKCicilan = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/UK/cicilan/Form"
  );
const BuatTagihanMABA = () =>
  import(/* webpackChunkName: "group-tagihan"*/ "../views/tagihan/PMB/Form");
const BuatTagihanWisuda = () =>
  import(/* webpackChunkName: "group-tagihan"*/ "../views/tagihan/wisuda/Form");
const DataTransaksi = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/Main"
  );
const DataTransaksiPMB = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/TransaksiPMB"
  );
const ValidasiPembayaran = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/validasi/Main"
  );
const ValidasiPembayaranPerMahasiswa = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/validasi/ValidasiPembayaranPerMahasiswa"
  );
const TransaksiPembayaran = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/TransaksiPembayaran"
  );

const Tertagih = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/Tertagih"
  );
const TertagihBeasiswa = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/TertagihBeasiswa"
  );
const DataTagihanBiaya = () =>
  import(/* webpackChunkName: "group-tagihan"*/ "../views/tagihan/Main");

const DataBiayaTagihanPMB = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/BiayaTagihanPMB"
  );

const MahasiswaCicilan = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/cicilan/Main"
  );

const DataTransaksiCicilan = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/transaksi-pembayaran/TransaksiCicilan"
    );
const PembayaranLainyaMhs = () =>
  import(
    /* webpackChunkName: "group-tagihan"*/ "../views/tagihan/pembayaran-mhs/Main"
    );
export const routes = {
  path: "/tagihan",
  name: "tagihan-route",
  component: Layout,
  children: [
    {
      name: "tagihan-pmb-list",
      path: "/tagihan-biaya-list/pmb",
      component: DataBiayaTagihanPMB,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-uk-list",
      path: "/tagihan-biaya-list/uk",
      component: DataTagihanBiaya,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-sp-list",
      path: "/tagihan-biaya-list/sp",
      component: DataTagihanBiaya,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-maba-create",
      path: "/tagihan-maba-create",
      component: BuatTagihanMABA,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-sp-create",
      path: "/tagihan-sp-create",
      component: BuatTagihanSP,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-uk-create",
      path: "/tagihan-uk-create",
      component: BuatTagihanUK,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },

    {
      name: "tagihan-uk-cicilan-create",
      path: "/tagihan-uk-cicilan-create",
      component: BuatTagihanUKCicilan,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-biaya-list",
      path: "/tagihan-biaya-list/:type?",
      component: DataTagihanBiaya,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-list",
      path: "/tagihan-list",
      component: DataTransaksi,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan", "transaksi"],
      },
    },
    {
      name: "tagihan-validasi",
      path: "/validasi",
      component: ValidasiPembayaran,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan", "transaksi"],
      },
    },
    {
      name: "validasi-tagihan-permhs",
      path: "/validasi-permhs",
      component: ValidasiPembayaranPerMahasiswa,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan", "transaksi"],
      },
    },
    {
      name: "transaksi",
      path: "/transaksi/:trxid?",
      component: TransaksiPembayaran,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "transaksi-pembayaran",
      path: "/transaksi-pembayaran/:trxid?",
      component: TransaksiPembayaran,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-tertagih",
      path: "/tagihan-tertagih",
      component: Tertagih,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-tertagih-beasiswa",
      path: "/tagihan-tertagih-beasiswa",
      component: TertagihBeasiswa,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },

    {
      name: "tagihan-wisuda-create",
      path: "/tagihan-wisuda-create",
      component: BuatTagihanWisuda,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-cuti-create",
      path: "/tagihan-cuti-create",
      component: BuatTagihanUK,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },

    {
      name: "validasi-transaksi-pmb",
      path: "/validasi-transaksi-pmb",
      component: DataTransaksiPMB,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "mahasiswa-cicilan",
      path: "/mahasiswa-cicilan",
      component: MahasiswaCicilan,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "validasi-transaksi-uk-cicilan",
      path: "/validasi-transaksi-uk-cicilan",
      component: DataTransaksiCicilan,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
    {
      name: "tagihan-lainnya-permhs",
      path: "/tagihan-lainnya-permhs",
      component: PembayaranLainyaMhs,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tagihan"],
      },
    },
  ],
};
