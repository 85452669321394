const Layout = () =>
  import(/* webpackChunkName: "group-master" */ "@/layouts/menu/Main");

const HonorNgajar = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/Perhitungan/HonorNgajar"
  );

const HonorSoal = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/Perhitungan/HonorBuatSoal"
  );

const RekapDosbing = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/Perhitungan/RekapDosbing"
  );
const RekapDosenPenguji = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/Perhitungan/RekapDosenPenguji"
  );

export const routes = {
  path: "/perhitungan",
  name: "perhitungan-route",
  component: Layout,
  children: [
    {
      name: "honor-ngajar",
      path: "honor-ngajar",
      component: HonorNgajar,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "honor-soal",
      path: "honor-soal",
      component: HonorSoal,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "rekap-dosbing",
      path: "rekap-dosbing",
      component: RekapDosbing,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "rekap-dosenpenguji",
      path: "rekap-dosenpenguji",
      component: RekapDosenPenguji,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
  ],
};
