import { createStore } from "vuex";
import main from "./main";
import feederpddikti from "./feederpddikti";
import sideMenuMdp from "./side-menu-mdp";
import auth from "./auth";
import userdata from "./userdata";
import notifications from "./notifications";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const store = createStore({
  modules: {
    auth,
    userdata,
    main,
    feederpddikti,
    sideMenuMdp,
    notifications,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "userdata", "main", "feederpddikti"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export function useStore() {
  return store;
}

export default store;
