import API from "../api";
export const mahasiswa = {
  PesertaDidik: new API("peserta_didik"),
  RegistasiPD: new API("registrasi_peserta_didik"),
  RwyRegPD: new API("rwy_reg_pd_sms"),
  Skpi: new API("Skpi"),
  KartuKendaliAkademik: new API("kartuKendaliAkademik"),
  RegisterMhsWisuda: new API("RegisterMhsWisuda"),
  Wisuda: new API("wisuda"),
  Mhs: new API("mhs"),
  JenisBeasiswa: new API("jenisbeasiswa"),
  BeasiswaPd: new API("beasiswa_pd"),
  SettingYudisium: new API("settingyudisium"),
  Ijazah: new API("ijazah"),
};
