import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { abilities } from "@/utils/ability";
import { routes as tagihanroutes } from "./tagihan";
import { routes as masterroutes } from "./master";
import { routes as perhitungan } from "./perhitungan";
import { routes as pmb } from "./pmb";
import { routes as keuanganroutes } from "./keuangan";
import { viewList } from "./view-list";

const Auth = () =>
  import(/* webpackChunkName: "group-general" */ "../views/auth/Main.vue");

const PesertaDidik = () =>
  import(
    /* webpackChunkName: "group-mahasiswa"*/ "../views/mahasiswa/pesertadidik/Main"
  );
const BukaTutupAbsenMhs = () =>
  import(
    /* webpackChunkName: "group-mahasiswa" */ "../views/mahasiswa/kuliah/BukaTutupAbsenMhs"
  );
const ValidasiPengisianKRS = () =>
  import(
    /* webpackChunkName: "group-mahasiswa" */ "../views/mahasiswa/kuliah/ValidasiPengisianKRS"
  );

// const Profile = () =>
//   import(/* webpackChunkName: "group-general" */ "../views/user/profile/Main");
const SwitchRole = () =>
  import(
    /* webpackChunkName: "group-general" */ "../views/configuration/SwitchRole"
  );
const ErrorPage = () =>
  import(
    /* webpackChunkName: "group-general" */ "../views/error-page/Main.vue"
  );
const Configuration = () =>
  import(/* webpackChunkName: "group-general" */ "../views/configuration/Main");

const DashboardMDP = () =>
  import(
    /* webpackChunkName: "group-general"*/ "../views/dashboard-mdp/Main.vue"
  );
const Layout = () =>
  import(/* webpackChunkName: "group-general" */ "@/layouts/menu/Main");

const GeneralReport = () =>
  import(
    /* webpackChunkName: "group-general" */ "@/views/dashboard-mdp/GeneralReport"
  );

const routes = [
  {
    path: "/login",
    name: "login",
    component: Auth,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: DashboardMDP,
        meta: {
          requiresAuth: true,
          general: true,
        },
      },
    ],
  },
  tagihanroutes,
  masterroutes,
  perhitungan,
  pmb,
  keuanganroutes,
  {
    path: "/mhs",
    name: "mahasiswa-route",
    component: Layout,
    children: [
      {
        name: "pesertadidik",
        path: "pesertadidik",
        component: PesertaDidik,
        meta: {
          requiresAuth: true,
          general: false,
          ability: ["pesertadidik", "pesertadidik:list"],
        },
      },
      {
        name: "buka-tutup-absen",
        path: "buka-tutup-absen",
        component: BukaTutupAbsenMhs,
        meta: {
          requiresAuth: true,
          general: false,
          ability: ["tagihan"],
        },
      },
      {
        name: "validasi-pengisian-krs",
        path: "validasi-pengisian-krs",
        component: ValidasiPengisianKRS,
        meta: {
          requiresAuth: true,
          general: false,
          ability: ["tagihan"],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
  {
    path: "/page",
    component: Layout,
    children: [
      {
        path: "/config/:tab?",
        name: "config",
        component: Configuration,
        meta: {
          requiresAuth: true,
          general: false,
          ability: ["config"],
          team: ["superadmin", "adminstaff"],
        },
      },
      {
        path: "/switchrole",
        name: "switchrole",
        component: SwitchRole,
        meta: {
          requiresAuth: true,
          general: false,
          ability: ["config"],
          team: ["superadmin"],
        },
      },
      // {
      //   path: "/profile",
      //   name: "profile",
      //   component: Profile,
      //   meta: {
      //     requiresAuth: true,
      //     general: true,
      //   },
      // },
      {
        path: "/general-report",
        name: "general-report",
        component: GeneralReport,
        meta: {
          requiresAuth: true,
          general: true,
        },
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});
viewList.forEach((list) => {
  let ability = list?.ability != undefined ? list.ability : list.path;

  router.addRoute(list.parent, {
    path: `/${list.path}/list`,
    name: `list-${list.path}`,
    component: list.list,
    meta: {
      requiresAuth: true,
      general: false,
      ability: [ability + ":list"],
      name: "Lihat " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/:id/edit`,
    name: `edit-${list.path}`,
    component: list.edit,
    meta: {
      requiresAuth: true,
      general: false,
      ability: [ability + ":update", ability + ":edit"],
      name: "Edit " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/new`,
    name: `create-${list.path}`,
    component: list.create,
    meta: {
      requiresAuth: true,
      general: false,
      ability: [ability + ":create", ability + ":new"],
      name: "Create " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/:id/view`,
    name: `view-${list.path}`,
    component: list.view,
    meta: {
      requiresAuth: true,
      general: false,
      ability: [ability + ":view", ability],
      name: "Lihat " + list.path.toUpperCase(),
    },
  });
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const loginDone = store.getters["auth/loginDone"];
  const u_abs = store.getters["auth/abilities"];
  if (to.matched.some((record) => record?.meta?.requiresAuth)) {
    if (!isAuthenticated) next({ name: "login" });
    else if (!loginDone) next({ name: "login" });
    else if (u_abs !== null && u_abs !== "") {
      const su = abilities.isSuperAdmin(u_abs, to);
      //const access = su ? su : abilities.cekAbility(u_abs, to, next);
      const access = su
        ? su
        : to.matched.some((r) => !r?.meta?.general) &&
          u_abs.filter((e) => to.meta?.ability?.indexOf(e) !== -1).length > 0;
      if (to.name !== "dashboard" && !access) {
        store.dispatch(
          "main/responseMessage",
          "Maaf anda tidak memiliki hak akses ke halaman/fitur " +
            (to.meta?.name ? to.meta?.name : "tersebut!")
        );
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else next();
  } else {
    if (!isAuthenticated && to.name !== "login") next({ name: "login" });
    else if (to.name !== "login" && to.name !== "dashboard" && isAuthenticated)
      next({ name: "dashboard" });
    else next();
  }
});
router.beforeResolve(async (to) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isExpire = store.getters["auth/isExpire"];
  const isSessionLocked = store.getters["auth/isSessionLocked"];

  if (to.name !== "login" && isAuthenticated)
    if (!isSessionLocked && isExpire) store.dispatch("auth/RefreshToken");
});

export default router;
