import feather from "feather-icons";
import Tabulator from "tabulator-tables";
import store from "@/store";

const tabulators = {
  localDataTable(tableRef, columns) {
    return new Tabulator(tableRef.value, {
      reactiveData: true, //enable data reactivity
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "Data tidak ditemukan",
      columns: columns,
      renderComplete() {
        feather.replace({
          "stroke-width": 1.5,
        });
      },
    });
  },
  remoteDataTable(
    tableRef,
    url,
    columns,
    pageSize = false,
    customHeader = null,
    ajaxParam = null
  ) {
    let target = process.env.VUE_APP_KEUANGAN + url;
    if (url.search("http") >= 0) {
      target = url;
    }
    return new Tabulator(tableRef.value, {
      pagination: "remote", //enable remote pagination
      ajaxURL: target,
      ajaxConfig: {
        headers: customHeader !== null ? customHeader : this.setHeader(),
      },
      paginationSize: 10, //optional parameter to request a certain number of rows per page
      paginationInitialPage: 1, //optional parameter to set the initial page to load
      ajaxFiltering: true,
      ajaxSorting: true,
      ajaxParams: ajaxParam, //ajax parameters
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "Data tidak ditemukan",
      paginationSizeSelector: pageSize ? [10, 20, 50, 100, true] : false,
      columns: columns,
      dataLoaded: function () {
        //do something
        console.log("data is loaded");
        store.commit("main/setTabulatorDataLoaded");
      },
      renderComplete() {
        feather.replace({
          "stroke-width": 1.5,
        });
      },
      ajaxResponse: function (url, params, response) {
        //url - the URL of the request
        //params - the parameters passed with the request
        //response - the JSON object returned in the body of the response.
        if (response?.last_page == null) {
          response["last_page"] = 0;
        }

        if (response?.data == null) {
          response["data"] = [];
        }

        return response; //return the response data to tabulator
      },
      ajaxError: function (error) {
        //error - the returned error object
        if (error.status == 401) {
          store.dispatch("auth/RefreshToken");
        }
        console.log(error);
      },
    });
  },

  ajaxConfig() {
    return {
      headers: this.setHeader(),
    };
  },
  setHeader() {
    const accessToken = store.getters["auth/accessToken"];
    const tahunBiaya = store.getters["main/currentTahunBiaya"];
    const kelompokBiaya = store.getters["main/currentKelompokBiaya"];
    /*const tokendikti = store.getters["feederpddikti/feedertoken"];
    const institusi = store.getters["main/currentInstitusi"];
    const semester = store.getters["main/currentSemester"];
    const fakultas = store.getters["main/currentFakultas"];*/
    const aT = JSON.parse(localStorage.getItem("_bh"));
    return {
      Accept: "application/json", //tell the server we need JSON back
      Authorization: "Bearer " + aT,
      currentTahunBiaya: tahunBiaya,
      currentKelompokBiaya: kelompokBiaya,
    };
  },
};

const install = (app) => {
  app.config.globalProperties.$list = tabulators;
};

export { install as default, tabulators as tblTabulator };
